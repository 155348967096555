<template>
  <div class="wrap" :class="{open: open}" ref="collapse_inner">
    <header v-if="title">
      <slot name="header-inner">
        {{title}}
      </slot>
    </header>
    <div>
      <transition
        name="content"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div v-show="open">
          <slot></slot>
        </div>
      </transition>
      <footer :class="{open: open}">
        <i class="chevron" @click="toggle" />
      </footer>
    </div>

    <tw-to-top v-if="toTop && mounted" :container="$refs.collapse_inner" class="detail-to-top" />
  </div>
</template>

<script>
import { collapseTransition } from '@/utils/nextFrame';
import TwToTop from '@/components/atoms/TwToTop';

export default {
  name: 'TwCollapse',
  mixins: [collapseTransition],
  components: {
    TwToTop,
  },
  props: {
    title: String,
    initialOpen: Boolean,
    toTop: Boolean,
  },
  data() {
    return {
      open: this.initialOpen,
      mounted: false,
    }
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  background: $color_white;
  z-index: 2;
  box-shadow: 0px 4.11278px 8.22556px rgba(170, 170, 204, 0.5);
  border-radius: 6px;
}

header {
  width: calc(100% - 32px);
  padding: 12px;
  margin: 0 auto;
  border-bottom: 1px solid transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $color_gray_800;
}

.wrap.open header {
  border-bottom: 1px solid $color_gray_300;
}

footer {
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 34px;
  background: $color_white;
  border-radius: 0px 0px 6px 6px;
  z-index: 2;

  .chevron {
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 4px;
    left: 50%;
    margin-left: -12px;
    transform: rotate(0deg);
    background: url(../../assets/images/icons/chevron_down.svg) no-repeat center;
    background-size: 100% auto;
    cursor: pointer;
    transition: transform .3s;
  }
}

.content-enter-active,
.content-leave-active {
  transition: height .3s;
}

footer.open .chevron {
  transform: rotate(180deg);
}
</style>
