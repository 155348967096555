<template>
  <div class="totop" :class="{ show: isShow }">
    <a class="link" @click="toHead()"><img src="@/assets/images/icons/up.svg"></a>
  </div>
</template>

<script>
import vueScrollto from 'vue-scrollto'

export default {
  name: 'TwToTop',
  props: {
    element: {
      default: 'head',
    },
    container: { // スクロール要素 親からrefで受け取ります
      default: '',
    },
  },
  data() {
    return {
      isShow: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.scrollCheck, true)
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollCheck)
  },
  methods: {
    toHead() {
      let container = {}
      if (this.container) container = { container: this.container }
      this.scrollTo(this.element, container, 500)
    },
    scrollTo(element, options = {}, time = 500) {
      const _defaultOptions = {
        easing: [0.86, 0.0, 0.07, 1.0],
        cancelable: true,
      }
      const setOptions = Object.assign(_defaultOptions, options)
      vueScrollto.scrollTo(element, time, setOptions)
    },
    // スクロール判定イベント toTop表示制御
    scrollCheck() {
      // 親からpropsで受け取った要素に、スクロールする高さがない場合は表示しません
      // スクロールしきい値400pxを超えた場合に、フェードインします
      if (this.container.clientHeight < this.container.scrollHeight && this.container.scrollTop > 400) {
         this.isShow = true
      } else this.isShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.totop {
  position: absolute;
  bottom: 120px;
  right: 40px;
  z-index: 999;
  transition: all 0.2s;
  opacity: 0;
  &.show {
    opacity: 1;
  }
  &.detail-to-top {
    position: fixed;
    bottom: 60px;
    right: 120px;
  }
}
.link {
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fff;
  border-radius: 50%;
  box-shadow: 1px 4px 12px rgba(207, 219, 238, 0.67), 4px 4px 12px rgba(207, 219, 238, 0.48), 5px -5px 12px #FFFFFF;
  > img {
    width: 24px;
    height: auto;
  }
}
</style>
