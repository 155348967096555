<template>
  <div class="tw_approval_list">
    <tw-collapse title="Update History">
      <div class="approval_list">
        <div class="approval_list_item" v-for="(item, index) in items" :key="index">
          <header>
            <div v-if="item.updateUserName" class="column">
              <div class="user">
                <figure class="avatar table"><img :src="item.updateUserIcon"></figure>
                <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{item.updateUserName}}</div>
                  <div class="from_name">{{item.updateUserName}}</div>
                </el-tooltip>
              </div>
              <div class="between">
                <div class="date">{{item.updateDate | dateTimeFormat}}</div>
                <div class="action">{{getStatus(item)}}</div>
              </div>
            </div>
          </header>
        </div>
        <div v-if="!items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
      </div>
    </tw-collapse>
  </div>
</template>

<script>
import TwCollapse from '@/components/molecules/TwCollapse';

export default {
  name: 'TwUpdateHistory',
  components: {
    TwCollapse,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    getStatus: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin-bottom: 16px;

  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }

  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }

  header {
    display: flex;
    justify-content: space-between;

    .column {
      width: 100%;
    }

    .user {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      font-size: 12px;
      line-height: 20px;
      color: $color_black;

      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .between {
      display: flex;
      justify-content: space-between;
    }
    .date, .action {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }
  }

  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }
}
</style>
