import _ from 'lodash';
import { LakeelValidation } from 'lib-lakeel-validation';
import { RESPONSIBLE_FLG } from 'lib-tw-common';

const LAKEEL_SYNERGY_LOGIC_HOSTNAME = process.env.VUE_APP_LAKEEL_SYNERGY_LOGIC_HOSTNAME;

export default {
  data() {
    return {
      validationService: new LakeelValidation(LAKEEL_SYNERGY_LOGIC_HOSTNAME),
      validResult: {},
      rules: {},
      errors: [],
    };
  },
  methods: {
    // 個別バリデーション
    // eslint-disable-next-line no-unused-vars
    async onEventValidation(rule, value, callback) {
      await this.validationService.lakeelValidAll.call(this, {formName: 'form', auto: true, field: 'validResult', lslHostName: LAKEEL_SYNERGY_LOGIC_HOSTNAME, relationMsgDisplayType: 'first'}, rule.field);
      // console.log(this.validResult);
    },
    // 一括バリデーション
    async validationCheck(ref) {
      try {
        await this.validationService.lakeelValidAll.call(this, {formName: ref, auto: true, field: 'validResult', lslHostName: LAKEEL_SYNERGY_LOGIC_HOSTNAME, relationMsgDisplayType: 'first'});
        if (!this.validResult.isError) {
          this.errors = [];
        } else {
          this.errors = _.map(_.uniqBy(this.validResult.error, o => o.validPropertyName), 'validPropertyName');
        }
        return !this.validResult.isError;
      } catch (e) {
        this.$store.dispatch('SHOW_ALERT', '問題が発生しました。');
        return false;
      }
    },
    // Element UIに依存しない配列項目の単体バリデーション
    // NOTE: PH7時点では未使用のため未完成・未検証
    async validateItem(rule, value, callback) {
      // console.log(rule)
      // const fields = rule.field.split('.');
      // const tableName = fields[0].split('[')[0];
      // const groupName = fields[1];
      // const itemName = fields[2];
      // const tableSchema = _.find(this.schemas.tables, {variableName: tableName});
      // if (!tableSchema) {
      //   return;
      // }
      // const groupSchema = _.find(tableSchema.groups, {variableName: groupName});
      // const itemSchema = _.find(groupSchema.children, {key: itemName}) || {};

      const rules = [{
        value: {
          first: value,
          // second: validateValue2  // 相関チェック時
        },
        validationId: rule.validationId,
        propertyShowName: {
          val1propertyName: rule.field,
          val1Name: rule.label
        },
        lakeelMessageSourceFlg: true,
        validStopWithError: false, // 必須でない、デフォルト値：true；trueである場合、エラーがある時、チェックが終了する、各項目に別々に設定できます
        logFlg: false // 必須でない、デフォルト値：false；trueである場合、ログが出力される
        // locale: 'ja-jp' // 必須でない、表示エラーメッセージのlocaleを指定する
      }];
      // console.log(rules);
      const result = await this.validationService.validate(rules);
      // console.log(result);
      if (result[0].validResult) {
        this.onValidate(rule.field, true, null);
        callback();
      } else {
        this.onValidate(rule.field, false, result[0].validMessage.message);
        callback(new Error(result[0].validMessage.message));
      }
      // return !this.validResult.isError;
    },
    // Element UIに依存しない配列項目のバリデーションAll
    // https://doc.media.lakeeldx.com/lakeel-components/validation/library/lib-lakeel-validation.html
    async validateArrayItems(name) {
      const tableName = name.split('.')[0];
      const arrayName = _.get(name.split('.'), '[1]');
      const targetArray = tableName + (arrayName ? `.${arrayName}` : '');
      const tableSchema = _.find(this.schemas.tables, {variableName: tableName});
      if (!tableSchema) {
        return;
      }
      const rules = _.flatten(_.get(this.cloneItems, targetArray).map((line, lineIndex) => {
        return _.reduce(line, (result, group, groupName) => {
          const groupSchema = _.find(tableSchema.groups, {variableName: groupName});
          if (groupSchema) {
            _.forEach(group, (value, itemName) => {
              const itemSchema = _.find(groupSchema.children, {key: itemName}) || {};
              if (_.isEmpty(itemSchema.validationId)) {
                return;
              }
              // 非表示項目、FromToに丸のない項目はバリデーションしない
              if (itemSchema.hide || !itemSchema[_.get(this, 'tradeManagement.responsibleFlg') === RESPONSIBLE_FLG.FROM ? 'from' : 'to']) {
                return;
              }
              const validationId = this.draftValid ? _.reject(itemSchema.validationId, s => s === 'tw-isNotEmpty') : itemSchema.validationId;
              result.push({
                value: {
                  first: value,
                  // second: validateValue2  // 相関チェック時
                },
                validationId: [...validationId],
                propertyShowName: {
                  val1propertyName: arrayName ? `${tableName}.${arrayName}[${lineIndex}].${groupName}.${itemName}` : `${tableName}[${lineIndex}].${groupName}.${itemName}`,
                  val1Name: itemSchema.label
                },
                lakeelMessageSourceFlg: true,
                validStopWithError: false, // 必須でない、デフォルト値：true；trueである場合、エラーがある時、チェックが終了する、各項目に別々に設定できます
                logFlg: false // 必須でない、デフォルト値：false；trueである場合、ログが出力される
                // locale: 'ja-jp' // 必須でない、表示エラーメッセージのlocaleを指定する
              });
            });
          }
          return result;
        }, []);
      }));
      // console.log(rules);
      const result = await this.validationService.validate(rules);
      const isError = !(result.length === 1 && result[0].validResult);
      // console.log(result, isError);
      if (!isError) {
        // this.errors = []; // 先にバリデーションオールを走らせるため、ここではエラーをクリアしない
        // console.log('invalid.');
      } else {
        // 先のバリデーションオールで、errorsに入っている可能性があるため、ここではエラーを追加する
        const arrayErrors = _.map(_.uniqBy(result, o => o.validPropertyName), 'validPropertyName');
        this.errors = _.uniq(this.errors.concat(arrayErrors));
        return false;
      }
      // console.log(this.errors);
      return !isError;
    }
  }
};
