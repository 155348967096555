<template>
  <div>
    <tw-header :title="title" />
    <div class="tw_container">
      <tw-breadcrumbs v-if="breadcrumbs.length" :pages="breadcrumbs" />

      <div v-if="isTwAdmin" class="buttons">
        <tw-button v-if="!editable" type="secondary" size="small" icon="edit" @click="toggleEdit">Edit</tw-button>
        <template v-else>
          <tw-button type="default" size="small" @click="toggleEdit">Cancel</tw-button>
          <tw-button type="secondary" size="small" icon="plus" @click="addCopy" :disabled="!selectRowInformationId">Add Copy</tw-button>
          <tw-button type="secondary" size="small" icon="plus" @click="addNew">Add New</tw-button>
        </template>
      </div>

      <tw-table-system :schemas="tableSchemas" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="false" :serverPagination="true" :highlight-current-row="editable" @row-click="rowClick"  @link-click="linkClick" @news-edit-click="onNewsEditClick" @paging="onPaging" height="800px" />
    </div>

    <tw-drawer :close-confirm="true" @close="drawerCloseConfirm" mode="edit">
      <template slot>
        <tw-news-edit-inner v-if="$store.state.drawerShow" :isShow="$store.state.drawerShow" :informationId="selectInformationId" :editState="editState" @update-news="updateNews" />
      </template>
    </tw-drawer>
  </div>
</template>

<script>
import _ from "lodash";
import { DEFAULT_PAGENATION, INFORMATIONS_PUBLISHED_FLG, INFORMATIONS_API_USE_CODE, INFORMATIONS_SEARCH_SORT_TARGET } from 'lib-tw-common';
// import { DEFAULT_PAGENATION, INFORMATIONS_PUBLISHED_STATUS_VARIABLES } from 'lib-tw-common';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwNewsEditInner from '@/components/templates/TwNewsEditInner';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import schemas from '@/dictionaries/listNews.json';

export default {
  name: 'NewsList',
  mixins: [mixinEntityList],
  components: { TwDrawer, TwNewsEditInner },
  data() {
    return {
      editable: false, // 編集フラグ
      limit: DEFAULT_PAGENATION.LIMIT,
      items: null,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,

      selectRowInformationId: '',
      selectInformationId: '', // 選択中のニュースID
      editState: '', // 編集状態
    };
  },
  computed: {
    // TW管理者かどうか
    isTwAdmin() {
      return this.$store.getters.isTwAdmin;
    },
    // タイトル
    title() {
      return this.editable ? this.$t('BreadCrumbs.News Maintenance') : this.$t('BreadCrumbs.News');
    },
    // パンくず
    breadcrumbs() {
      return this.editable ? [{ label: this.$t('BreadCrumbs.Dashboard'), to: '/' }, { label: this.$t('BreadCrumbs.News Maintenance') }] : [{ label: this.$t('BreadCrumbs.Dashboard'), to: '/' }, { label: this.$t('BreadCrumbs.News') }];
    },
    // テーブルスキーマ(権限と編集フラグにより変わる)
    tableSchemas() {
      return this.editable ? schemas.adminUser : schemas.commonUser;
    },
  },
  methods: {
    toggleEdit() {
      this.editable = !this.editable;
      this.items = null;
      this.fetchNewsList();
    },
    addCopy() {
      if (this.selectRowInformationId) {
        this.selectInformationId = this.selectRowInformationId;
        this.editState = 'Copy';
        this.$store.commit('SET_DRAWER', true);
      }
    },
    addNew() {
      this.editState = 'New';
      this.$store.commit('SET_DRAWER', true);
    },
    fetchNewsList(queries) {
      // this.items = null;

      // bff_in_1 お知らせ一覧取得BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/informations',
          query: queries || {
            conditions: {
              informationTitle: null,
              informationDateFrom: null,
              informationDateTo: null,
              publishedFlg: this.editable ? null : INFORMATIONS_PUBLISHED_FLG.PUBLISHED,
              newsType: null,
              priorityFlg: null,
              updateUserId: null,
              updateDate: null,
            },
            apiUseCode: this.editable ? INFORMATIONS_API_USE_CODE.MAINTENANCE : INFORMATIONS_API_USE_CODE.LIST, // お知らせ管理取得の場合はMAINTENANCE
            limit: this.limit,
            offset: this.offset,
            sort: this.sort,
          },
        },
      };

      $api
        .request(params)
        .then((res) => {
          this.items = res.informationList || [];
          this.totalCount = res.totalCount || 0;
          this.pageCount = res.pageCount || 0;
        })
        .catch((err) => {
          this.items = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    linkClick(row) {
      if (this.editable) {
        // this.$router.push({name: 'NewsDetail', params: { informationId: row.informationId }});
        this.editState = 'Edit';
        this.onNewsEditClick(row.informationId);
      }
    },
    rowClick(row) {
      if (this.editable) {
        this.selectRowInformationId = row.informationId;
        return;
        // return this.$store.state.drawerShow;
      } else {
        this.$router.push({
          name: 'NewsDetail',
          params: {
            informationId: row.informationId,
          },
        });
      }
    },
    onNewsEditClick(informationId) {
      this.selectInformationId = informationId;
      this.editState = 'Edit';
      this.$store.commit('SET_DRAWER', true);
    },
    drawerCloseConfirm() {
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
      .then(() => {
        this.selectInformationId = '';
        this.editState = '';
        this.$store.commit('SET_DRAWER', false);
      })
    },
    onPaging({ target, sortType, currentPage }) {
      if (target && sortType) {
        const key = _.get(_.find(this.tableSchemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [INFORMATIONS_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      this.fetchNewsList();
    },
    updateNews() {
      this.selectRowInformationId = '';
      this.selectInformationId = '';
      this.fetchNewsList();
    },
  },
  created() {
    // ページバックでドロワー開いたままを抑制
    if (this.$store.state.drawerShow) this.$store.commit('SET_DRAWER', false);

    this.fetchNewsList();
  },

};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;
}

// .news_list {
//   width: 840px;
//   margin: 0 auto;
//   background: $color_white;
//   border-radius: 6px;
//   @include card_shadow;
//   padding: 40px 108px;
//   height: calc(100% - 80px);
//   overflow-y: auto;
// }

// .news_list_item {
//   display: flex;
//   height: 44px;
//   align-items: center;
//   font-size: 14px;
//   line-height: 20px;
//   color: $color_black;
//   border-top: 1px solid $color_gray_300;
//   cursor: pointer;

//   &:first-child {
//     border-top: 0;
//   }

//   &:hover {
//     background: $color_gray_background;
//   }

//   // .date {
//   //   width: 104px;
//   //   font-size: 12px;
//   //   line-height: 18px;
//   //   color: $color_gray_600;
//   //   flex-shrink: 0;
//   // }

//   // .new {
//   //   width: 70px;
//   //   flex-shrink: 0;

//   //   .new_icon {
//   //     display: flex;
//   //     align-items: center;
//   //     justify-content: center;
//   //     text-align: center;
//   //     width: 30px;
//   //     height: 18px;
//   //     color: $color_white;
//   //     font-weight: bold;
//   //     font-size: 10px;
//   //     line-height: 1px;
//   //     padding: 4px 2px;
//   //     background: $color_primary_blue;
//   //     border-radius: 2px;
//   //   }
//   // }

//   .title {
//     width: 100%;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }
// }

// img.loading {
//   display: block;
//   width: 48px;
//   height: 48px;
//   margin: 24px auto;
// }

// .empty_message {
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 27px;
//   color: $color_black;
//   margin-top: 33px;
// }
</style>
