<template>
  <div class="news_editor">
    <header>
      <h1>
        <span v-if="form.publishedFlg === INFORMATIONS_PUBLISHED_FLG.DRAFT && editState !== 'Copy'" class="draft_label">Draft</span>
        <span>{{ $t('BreadCrumbs.News Maintenance') }}</span>
        <span v-if="editState"> - {{editState}}</span>
      </h1>
      <tw-button v-if="editState === 'Edit' && !isFetchingNewsDetail" type="secondary" size="medium" icon="trash" @click="remove">Delete</tw-button>
    </header>

    <div v-if="isFetchingNewsDetail" class="news_editor">
      <el-skeleton :rows="5" animated />
    </div>
    <el-form v-else :model="form" :rules="rules" ref="form">
      <el-form-item label="News Title (English）" prop="informationTitle" class="el-form-item-full">
        <el-input type="text" v-model="form.informationTitle" maxlength="100" show-word-limit />
      </el-form-item>

      <el-form-item label="News Title（Local: Japanese）" prop="informationTitleLocal" class="el-form-item-full">
        <el-input type="text" v-model="form.informationTitleLocal" maxlength="100" show-word-limit />
      </el-form-item>

      <el-form-item label="Publication Start Date / Publication End Date" prop="informationDate">
        <el-date-picker
          v-model="form.informationDate"
          type="datetimerange"
          range-separator="to"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change.native="onDateRangeChange"
          start-placeholder="Start date"
          end-placeholder="End date" />
        <!-- <div>{{form.informationDate}}</div> -->
      </el-form-item>

      <el-form-item label="Importance" prop="priorityFlg">
        <el-checkbox class="size-large" v-model="form.priorityFlg" :true-label="PRIORITY_FLG.ON" :false-label="PRIORITY_FLG.OFF" @change="changePriorityFlg" />
      </el-form-item>

      <el-form-item label="News Type" prop="newsType" :class="{'is-required': form.priorityFlg === PRIORITY_FLG.ON}">
        <el-select class="unit" v-model="form.newsType" :disabled="form.priorityFlg === PRIORITY_FLG.OFF">
          <el-option
            v-for="item in NEWS_TYPE_VARIABLES"
            :key="item.id"
            :label="item.label"
            :value="item.code"
            class="news_type_item">
            <span style="float: left" class="importance_icon" :class="`news_type_${item.code}`"></span>
            <span >{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="News Text（English）" prop="informationText" class="el-form-item-full">
        <tw-button type="secondary" size="medium" class="button_preview" @click="openPreview('')">
          Preview
        </tw-button>
        <TwTiptapEditor v-model="form.informationText" class="tiptap_news_style" />
        <!-- <code>{{form.informationText}}</code> -->
        <!-- <div>{{form.informationText}}</div> -->
      </el-form-item>

      <el-form-item label="News Text （Local: Japanese）" prop="informationTextLocal" class="el-form-item-full">
        <tw-button type="secondary" size="medium" class="button_preview" @click="openPreview('local')">
          Preview
        </tw-button>
        <TwTiptapEditor v-model="form.informationTextLocal" class="tiptap_news_style" />
      </el-form-item>

      <!-- Preview Dialog -->
      <el-dialog :visible.sync="previewDialogVisible" center :close-on-click-modal="false" class="news_preview" :append-to-body="true">
        <div class="news_detail_item">
          <div  class="new_icon">NEW</div>
          <div class="title">
            <span class="importance_icon" :class="[`news_type_${form.newsType}`]" />
            {{previewTitle}}
          </div>
          <div class="date" v-if="form.informationDate">{{form.informationDate[0] || '' | dateFormat}}</div>
          <div class="contents tiptap_news_style" v-html="previewText" />
          <div class="attach_file" v-if="form.informationDocuments.length > 0">
            <p>Attach File</p>
            <template v-for="doc in form.informationDocuments">
              <div :key="doc.informationDocumentId" class="file">
                <div class="inner">
                  <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                    <div slot="content">{{doc.informationDocumentName}}</div>
                    <span>
                      <a class="file_name">{{doc.informationDocumentName}}</a>
                    </span>
                  </el-tooltip>
                  <span class="meta">{{doc.informationDocumentSize | fileSize}},&nbsp;{{doc.updateDate | dateTimeFormat}}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-dialog>

      <!-- 添付ファイル -->
      <div class="attachment" @dragover.prevent="()=> {dragin = true;}" @dragleave.prevent="()=> {dragin = false;}" @drop.prevent="(e)=> {dragin = false;setFile(e);}" :class="{enter: dragin}">
        <div class="attach">
          <div>Attach File</div>
          <div>
            <tw-button type="secondary" size="medium" class="choose_file" @click="loadFile">
              Choose Local File
              <input ref="fileInput" class="file_input" @change="setFile" type="file" multiple :accept="'.' + UPLOAD_FILE_WHITE_LIST.join(',.')" />
            </tw-button>
          </div>
        </div>

        <template v-for="(doc, index) in form.informationDocuments">
          <div :key="doc.informationDocumentId" class="file">
            <div class="inner">
              <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{doc.informationDocumentName}}</div>
                <!-- 登録済みでinformationDocumentIdがあるものはAPIからダウンロード -->
                <span v-if="doc.informationDocumentId">
                  <a class="file_name" @click.prevent="download(doc)" tabindex="-1">{{doc.informationDocumentName}}</a>
                </span>
                <!-- 今アップロードしようとしてるものはメモリ上のBASE64をダウンロード -->
                <span v-else>
                  <a class="file_name" :href="doc.informationDocumentValue" :download="doc.informationDocumentName">{{doc.informationDocumentName}}</a>
                </span>
              </el-tooltip>
              <span class="meta">{{doc.informationDocumentSize | fileSize}},&nbsp;{{doc.updateDate | dateTimeFormat}}</span>
            </div>
            <img class="close_times" src="@/assets/images/icons/times_black.svg" @click.stop.prevent="removeFile(index)">
          </div>
        </template>

        <div class="droparea">
          <img src="@/assets/images/dropfile.svg">
          Drop files here to upload.
        </div>
      </div>
    </el-form>
    <div class="buttons">
      <div style="display: flex; justify-content: flex-end">
        <tw-button type="secondary" size="medium"  @click="register(INFORMATIONS_PUBLISHED_FLG.DRAFT)">Draft</tw-button>
        <tw-button type="primary" size="medium" @click="register(INFORMATIONS_PUBLISHED_FLG.PUBLISHED)">Register</tw-button>
      </div>
    </div>

    <!-- updateHistory -->
    <el-row v-if="form.updateHistory">
      <el-col :span="10">
        <TwUpdateHistory :items="form.updateHistory" :getStatus="getNewsPublishedFlgLabel" style="margin-bottom:50px" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { LANGUAGE_CODE, NEWS_TYPE_VARIABLES, INFORMATIONS_PUBLISHED_FLG_VARIABLES, INFORMATIONS_PUBLISHED_FLG, PRIORITY_FLG, INFORMATIONS_API_USE_CODE } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinValidation from '@/utils/mixinValidation.js';
import { onDateChange } from '@/utils/searchUtil.js';
import TwButton from '@/components/atoms/TwButton';
import TwTiptapEditor from '@/components/molecules/TwTiptapEditor';
import TwUpdateHistory from '@/components/organisms/TwUpdateHistory';

// 拡張子を取得します
const getFileExtension = filename => {
  const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  return extension.toLowerCase();
};

const UPLOAD_FILE_WHITE_LIST = ['pdf', 'docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt', 'jpg', 'jpeg', 'png', 'gif', 'txt', 'zip', 'lzh', 'msg', 'eml'];

export default {
  name: 'TwNewsEditInner',
  mixins: [mixinValidation],
  props: {
    data: Object,
    informationId: String,
    editState: {
      type: String,
      default: '',
    },
    isShow: Boolean,
    propInformationDetail: Object,
  },
  components: {
    TwButton,
    TwTiptapEditor,
    TwUpdateHistory,
  },
  data() {
    return {
      NEWS_TYPE_VARIABLES,
      PRIORITY_FLG,
      INFORMATIONS_PUBLISHED_FLG,
      UPLOAD_FILE_WHITE_LIST,
      isFetchingNewsDetail: false,
      form:  {
        informationId: '',
        informationTitle: '',
        informationText: '',
        informationTitleLocal: '',
        informationTextLocal: '',
        // informationContents: [
        //   {
        //     languageCode: '',
        //     informationTitle: '',
        //     informationText: '',
        //   },
        // ],
        // informationDateFrom: '',
        // informationDateTo: '',
        informationDate: null,
        publishedFlg: '',
        newsType: null,
        priorityFlg: PRIORITY_FLG.OFF,
        informationDocuments: [
          // {
          //   informationDocumentId: '',
          //   informationDocumentName: '',
          //   informationDocumentValue: '',
          // },
        ],
      },
      dragin: false,
      previewDialogVisible: false,
      previewTitle: '',
      previewText: '',

      rules: {
        informationTitle: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty"],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true,
          }
        ],
        informationTitleLocal: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty"],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true,
          }
        ],
        priorityFlg: [
          {
            validationId: ['tw-isNotEmpty'],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation,
            trigger: 'change',
          }
        ],
        informationText: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty"],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true,
          }
        ],
        informationTextLocal: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty"],
            validator: this.onEventValidation,
            trigger: 'blur',
            required: true,
          }
        ],
        informationDate: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty"],
            validator: this.onEventValidation,
            trigger: 'change',
            required: true,
          }
        ],
      },
    };
  },
  methods: {
    // informationDetailをを投げて、formに合う値を返却
    getNewsDetailModel(detail) {
      const res =  {
        ...detail,
        informationTitle: detail.informationContents[0]?.informationTitle || '',
        informationText: detail.informationContents[0]?.informationText || '',
        informationTitleLocal: detail.informationContents[1]?.informationTitle || '',
        informationTextLocal: detail.informationContents[1]?.informationText || '',
        informationDate: detail.informationDateFrom ? [detail.informationDateFrom, detail.informationDateTo] : null,
      };
      _.unset(res, ['newFlg', 'publishedStatus', 'informationContents', 'informationDateFrom', 'informationDateTo', 'nextInformationId', 'prevInformationId']);
      // コピーの場合は掲載開始日・更新履歴・AttachFileは除外
      if (this.editState === 'Copy') {
        res.informationId = ''; // 空にして、新規登録にする
        res.informationDocuments = [];
        res.informationDate = null;
        res.updateHistory = [];
      }
      return res;
    },
    // お知らせ詳細取得
    async fetchNewsDetail() {
      if (!this.informationId) return;
      // bff_in_2 お知らせ詳細取得BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/informations/detail/{informationId}',
          query: {
            apiUseCode: INFORMATIONS_API_USE_CODE.MAINTENANCE
          },
          path: {
            informationId: this.informationId,
          },
        },
      };
      this.isFetchingNewsDetail = true;
      await $api
        .request(params)
        .then((res) => {
          const model = this.getNewsDetailModel(res.informationDetail);
          this.form = Object.assign(this.form, model);
        }).catch((err) => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        }).finally(() => {
          this.isFetchingNewsDetail = false;
        });
    },
    // PriorityFlgのチェックの切り替えで「newsType」のバリデーションルールを追加/削除
    changePriorityFlg(e) {
      // console.log('changePriorityFlg', e);
      if (e === PRIORITY_FLG.ON) {
        this.rules = Object.assign(this.rules, { newsType: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty"],
            validator: this.onEventValidation,
            trigger: 'change',
            required: true,
          }
        ]});
      } else {
        delete this.rules.newsType
      }
    },
    // プレビュー開く
    openPreview(type = '') {
      this.previewTitle = type === '' ? this.form.informationTitle : this.form.informationTitleLocal;
      this.previewText = type === '' ? this.form.informationText : this.form.informationTextLocal;
      // this.previewText = this.$sanitize(
      //   type === '' ? this.form.informationText : this.form.informationTextLocal,
      //   {
      //     '*': ['class', 'style'], // sanitize-htmlの設定styleを許可する
      //     a: [ 'href', 'name', 'target' ],
      //     img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ]
      //   },
      // ).replace(/\n/g, '<br>');
      this.previewDialogVisible = true;
    },
    handlePreviewClose(done) {
      this.$confirm('Are you sure to close this dialog?')
        .then(() => {done();})
        .catch(() => {});
    },
    // 添付ファイル系 start ======================================================
    loadFile() {
      this.$refs.fileInput.click();
    },
    setFile(e) {
      const files = _.get(e, 'target.files[0]') ? e.target.files : e.dataTransfer.files;
      const _file = _.get(e, 'target.files[0]') || _.get(e, 'dataTransfer.files[0]');
      if (!_file) return;
      if (files.length + this.form.informationDocuments.length > 10) {
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[10] exceeded.');
        return;
      }
      if (_.some(files, file => {
        if (file.name.length > 100) {
          this.$store.dispatch('SHOW_ALERT', 'Maximum length of file name[100(including extensions)] exceeded.');
          return true;
        }
      })) {
        return;
      }
      if (_.some(files, file => {
        if (file.size > 10485760) {
          this.$store.dispatch('SHOW_ALERT', 'Your file exceeds the maximum upload size: 10.0MB');
          return true;
        }
      })) {
        return;
      }
      if (_.some(files, file => {
        if (!UPLOAD_FILE_WHITE_LIST.includes(getFileExtension(file.name))) {
          this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: pdf,docx,doc,xlsx,xls,pptx,ppt,jpg,jpeg,png,gif,txt,zip,lzh');
          return true;
        }
      })) {
        return;
      }

      if (typeof FileReader === 'function') {
        _.forEach(files, file => {
          const reader = new FileReader();
          reader.onload = event => {
            this.form.informationDocuments.push({
              informationDocumentId: '',
              informationDocumentName: file.name,
              informationDocumentValue: event.target.result,
              informationDocumentSize: file.size,
            });
            e.target.value = '';
          };
          reader.readAsDataURL(file);
        });
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    // 添付ファイル削除（登録前）
    removeFile(index) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.form.informationDocuments.splice(index, 1);
        })
    },
    // 添付ファイル削除（登録後）
    removeAttachedFile(doc, index) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.$set(this.form.attachedDocuments, index, doc);
        })
    },
    download(doc) {
      const query = {
        base64Flg: 0,
        informationDocumentId: doc.informationDocumentId,
      };
      // bff_in_5 お知らせファイル取得BFF
      const apiCode = 'get_/v1/informations/file-download';

      $api
      .txtDownload(query, doc.informationDocumentName, 'tw-transaction-bff-api', apiCode)
      .then(() => {})
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    // 添付ファイル end ==========================================================

    // 登録 (公開・非公開)
    async register(publishedFlg = INFORMATIONS_PUBLISHED_FLG.DRAFT) {
      if (await this.validationCheck('form')) {
        // bff_in_3 お知らせ登録BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/informations',
          },
          data: ((form, publishedFlg) => {
            const res = { informationDetail: {
              ...form,
              informationContents: [
                {
                  languageCode: LANGUAGE_CODE.ENGLISH,
                  informationTitle: form.informationTitle,
                  informationText: form.informationText,
                },
                {
                  languageCode: LANGUAGE_CODE.JAPANESE,
                  informationTitle: form.informationTitleLocal,
                  informationText: form.informationTextLocal,
                },
              ],
              informationDateFrom: form.informationDate[0] || '',
              informationDateTo: form.informationDate[1] || '',
              publishedFlg: publishedFlg,
            }};
            delete res.informationDetail.newFlg;
            delete res.informationDetail.informationTitle;
            delete res.informationDetail.informationText;
            delete res.informationDetail.informationTitleLocal;
            delete res.informationDetail.informationTextLocal;
            delete res.informationDetail.informationDate;
            return res;
          })(this.form, publishedFlg),
        };
        // console.log('register', params);

        this.$store.commit('START_PROCESS');
        $api
          .request(params)
          .then((res) => {
            this.$emit('update-news', {type: 'edit/copy', informationId: res.informationDetail.informationId});
            this.$store.commit('END_PROCESS');
            setTimeout(() => {this.$store.dispatch('SHOW_COMPLETED');}, 500);
            this.$store.commit('SET_DRAWER', false);
          }).catch((err) => {
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
      } else {
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    remove() {
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?').then(() => {
        // bff_in_4 お知らせ削除BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/informations/delete/{informationId}',
            path: {
              informationId: this.informationId,
            },
          },
        };

        this.$store.commit('START_PROCESS');
        $api
          .request(params)
          .then(() => {
            this.$emit('update-news', {type: 'remove', informationId: ''});
            this.$store.commit('END_PROCESS');
            this.$store.commit('SET_DRAWER', false);
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
          })
          .catch((err) => {
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
      });
    },
    onDateChange,
    getNewsPublishedFlgLabel(item) {
      const action = INFORMATIONS_PUBLISHED_FLG_VARIABLES.find((i) => i.code === item.pastPublishedFlg)
      return action?.label || ''
    },
  },
  async created() {
    // propからお知らせ詳細を渡されたらそれを使う
    if (this.propInformationDetail) {
      const model = this.getNewsDetailModel(this.propInformationDetail);
      this.form = Object.assign(this.form, model);
      return;
    } else {
      // informationIdがあればお知らせ詳細を取得
      if (this.informationId) await this.fetchNewsDetail();
    }
    this.changePriorityFlg(this.form.priorityFlg)
  },
};
</script>

<style lang="scss" scoped>
.news_editor {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 35px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 20px;
    border-bottom: 1px solid $color_gray_300;
    margin-bottom: 24px;

    h1 {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      margin: 0;
      > span {
        margin-right: 10px;
      }
    }
  }

  .el-form-item {
    display: flex;
    margin: 0;
    padding: 0 0 24px;

    ::v-deep textarea {
      width: 456px;
      resize: none;
      min-height: 390px !important;
      box-shadow: inset 2.5px 2.5px 5px rgba(170, 170, 204, 0.5);
    }
  }

  ::v-deep label.el-form-item__label {
    width: 170px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    text-align: left;
    margin-right: 8px;
  }

  .button_preview {
    position: absolute;
    top: 100px;
    left: -160px;
  }

  .buttons {
    // position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 40px 0;
    background: $color_white;
    margin-left: -20px;
    padding-right: 20px;
    width: calc(100% + 40px);
    z-index: 3;

    .tw_button {
      margin-left: 16px;
      margin-right: 0;
    }
  }
}

.attachment {
  position: relative;
  font-size: 14px;
  line-height: 20px;

  .attach {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .file {
    position: relative;
    margin-top: 8px;
    padding-left: 16px;
    padding-right: 48px;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    // padding-bottom: 24px;
    background: $color_gray_100;

    &:first-child {
      margin-top: 12px;
    }

    &.has_error {
      background: rgba(224, 0, 1, 0.03);
    }

    .inner {
      display: flex;
      align-items: center;
      width: 100%;

      > .el-tooltip {
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color_dark_blue;
      }
    }

    .user {
      max-width: 200px;
      margin-right: 12px;
      display: flex;
      align-items: center;

      font-size: 12px;
      line-height: 20px;
      color: $color_black;

      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close_times {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      right: 16px;
      margin-top: -12px;
      cursor: pointer;
    }
  }

  .meta {
    flex-shrink: 0;
  }

  a.file_name {
    // max-width: 710px;
    color: $color_dark_blue;
    cursor: pointer;
    text-decoration: none;
  }

  .el-form-item {
    margin: 0;
  }

  .choose_file {
    position: relative;
    display: inline-block;
    // color: $color_dark_blue;
    // margin-top: 8px;
    // font-size: 12px;
    // line-height: 18px;
    // cursor: pointer;

    .file_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      pointer-events: none;
    }
  }

  .droparea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 138px;
    margin-top: 24px;
    background: $color_gray_100;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $color_dark_blue;
    pointer-events: none;
    z-index: 2;

    img {
      display: block;
      width: 84px;
      height: auto;
      margin-bottom: 9px;
    }
  }

  &.enter > * {
    pointer-events: none;
  }

  &.enter .droparea {
    border: 1px dashed $color_dark_blue;
  }
}

.el-form-item.el-form-item-full ::v-deep .el-form-item__content {
  flex: 1;
}

.el-select-dropdown__item.news_type_item {
  display: flex;
  align-items: center;
  .importance_icon {
    margin-right: 8px;
  }
}

.el-dialog__wrapper.news_preview::v-deep {
  .el-dialog__header {
    padding: 0;
  }
}
</style>
