<!-- eslint-disable import/no-extraneous-dependencies -->
<template>
<!-- <div> -->

  <div class="tiptap-editor el-input__inner" v-if="editor">
    <editor-content :editor="editor" />
    <div class="tiptap-editor-controls">
      <button type="button" @click="editor.chain().focus().undo().run()">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Undo</div>
          <img src="@/assets/images/tiptap_icons/undo.svg" alt="Undo" />
        </el-tooltip>
      </button>

      <button type="button" @click="editor.chain().focus().redo().run()">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Redo</div>
          <img src="@/assets/images/tiptap_icons/redo.svg" alt="Redo" />
        </el-tooltip>
      </button>

      <hr>

      <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
        <div slot="content">Heading</div>
        <el-select v-model="fontSize" @change="selectFontSize" placeholder="A" size="small" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          <el-option v-for="item in fontSizeArray" :key="item.v" :label="item.l" :value="item.v" />
        </el-select>
      </el-tooltip>

      <button type="button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Bold</div>
          <img src="@/assets/images/tiptap_icons/format_bold.svg" alt="bold" />
        </el-tooltip>
      </button>

      <button type="button" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Italic</div>
          <img src="@/assets/images/tiptap_icons/format_italic.svg" alt="Italic" />
        </el-tooltip>
      </button>

      <button type="button" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Underline</div>
          <img src="@/assets/images/tiptap_icons/format_underlined.svg" alt="Underline" />
        </el-tooltip>
      </button>

      <button type="button" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Strike</div>
          <img src="@/assets/images/tiptap_icons/strikethrough.svg" alt="Strike" />
        </el-tooltip>
      </button>

      <!-- editor.chain().focus().toggleLink().run() -->
      <button type="button" @click="toggleLink" :class="{ 'is-active': editor.isActive('link') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Link</div>
          <img src="@/assets/images/tiptap_icons/link.svg" alt="Link">
        </el-tooltip>
      </button>

      <button type="button" @click="toggleColor('#F00000')" :class="{ 'is-active': editor.isActive('textStyle')}">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Text color: Red</div>
          <img src="@/assets/images/tiptap_icons/format_color_text.svg" alt="Text color: Red" />
        </el-tooltip>
      </button>

      <hr>

      <button type="button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Bullet list</div>
          <img src="@/assets/images/tiptap_icons/format_list_bulleted.svg" alt="Bullet list" />
        </el-tooltip>
      </button>
      <button type="button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Ordered list</div>
          <img src="@/assets/images/tiptap_icons/format_list_numbered.svg" alt="Ordered list" />
        </el-tooltip>
      </button>

      <button type="button" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Blockquote</div>
          <img src="@/assets/images/tiptap_icons/format_quote.svg" alt="Blockquote" />
        </el-tooltip>
      </button>
      <button type="button" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
        <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">Code block</div>
          <img src="@/assets/images/tiptap_icons/code.svg" alt="Code block" />
        </el-tooltip>
      </button>
      <!-- <button type="button" @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button> -->
      <!-- <button type="button" @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </button> -->

      <!-- <button type="button" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        code
      </button>
      <button type="button" @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </button>
      <button type="button" @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>
      <button type="button" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
        paragraph
      </button> -->

      <!-- <button type="button" @click="editor.command.removeEmptyTextStyle()">CS</button> -->
    </div>
  </div>
  <!-- <code>{{value}}</code>
</div> -->
</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-2';
// eslint-disable-next-line import/no-extraneous-dependencies
import Blockquote from '@tiptap/extension-blockquote';
// eslint-disable-next-line import/no-extraneous-dependencies
import Bold from '@tiptap/extension-bold';
// eslint-disable-next-line import/no-extraneous-dependencies
import BulletList from '@tiptap/extension-bullet-list';
// eslint-disable-next-line import/no-extraneous-dependencies
import Code from '@tiptap/extension-code';
// eslint-disable-next-line import/no-extraneous-dependencies
import CodeBlock from '@tiptap/extension-code-block';
// eslint-disable-next-line import/no-extraneous-dependencies
import Document from '@tiptap/extension-document';
// // eslint-disable-next-line import/no-extraneous-dependencies
// import Dropcursor from '@tiptap/extension-dropcursor';
// // eslint-disable-next-line import/no-extraneous-dependencies
// import Gapcursor from '@tiptap/extension-gapcursor';
// eslint-disable-next-line import/no-extraneous-dependencies
import HardBreak from '@tiptap/extension-hard-break';
// eslint-disable-next-line import/no-extraneous-dependencies
import Heading from '@tiptap/extension-heading';
// eslint-disable-next-line import/no-extraneous-dependencies
import History from '@tiptap/extension-history';
// eslint-disable-next-line import/no-extraneous-dependencies
import HorizontalRule from '@tiptap/extension-horizontal-rule';
// eslint-disable-next-line import/no-extraneous-dependencies
import Italic from '@tiptap/extension-italic';
// eslint-disable-next-line import/no-extraneous-dependencies
import ListItem from '@tiptap/extension-list-item';
// eslint-disable-next-line import/no-extraneous-dependencies
import OrderedList from '@tiptap/extension-ordered-list';
// eslint-disable-next-line import/no-extraneous-dependencies
import Paragraph from '@tiptap/extension-paragraph';
// eslint-disable-next-line import/no-extraneous-dependencies
import Strike from '@tiptap/extension-strike';
// eslint-disable-next-line import/no-extraneous-dependencies
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';

import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
// import Color from '@tiptap/extension-color';
import { Color } from '@tiptap/extension-color';

export default {
  name: 'TwTiptapEditor',
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      fontSize: null,
      fontSizeArray: [
        {l: '', v: null},
        {l: 'H1', v: 1},
        {l: 'H2', v: 2},
        {l: 'H3', v: 3},
        {l: 'H4', v: 4},
        {l: 'H5', v: 5},
        {l: 'H6', v: 6},
      ],
    }
  },
  methods: {
    selectFontSize(fontSize) {
      console.log('fontSize', fontSize);
      this.editor.chain().focus().toggleHeading({ level: fontSize }).run();
    },
    toggleLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      console.log('previousUrl', previousUrl);
      if (previousUrl) {
        this.editor.chain().focus().unsetLink().run()
        return;
      } else {
        const url = window.prompt('URL', previousUrl)
        // cancelled
        if (url === null) return;

        // empty
        if (url === '') {
          this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
          return;
        }

        // update link
        this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
      }
    },
    toggleColor(color) {
      // if (this.editor.isActive('textStyle', { color: color })) {
      if (this.editor.isActive('textStyle')) {
        this.editor.chain().focus().unsetColor(color).run();
      } else {
        this.editor.chain().focus().setColor(color).run();
      }
    },
  },
  watch: {
    // 参考: https://tiptap.dev/installation/vue2#5-use-v-model
    value(value) {
      const isSame = this.editor.getHTML() === value; // HTML
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value); // JSON

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  mounted() {
    const _this = this;
    this.editor = new Editor({
      extensions: [
        // StarterKit,
        Blockquote,
        Bold,
        BulletList,
        Code,
        CodeBlock,
        // !WARN: 本来は編集時は`<span style="color:red;"`></span>`でラップされるが保存したものをTiptapで開こうとするとstyleが外れてしまうバグがある解決方法が不明、苦肉の策としてspanタグにはくくられるためCSSでspanタグに色つけしている。他の色を足せない、今後追加する機能によっては影響があるため注意
        // 参照: src/assets/styles/_news.scss:245
        Color.configure({
          types: ['textStyle'],
        }),
        Document,
        HardBreak,
        Heading,
        History,
        HorizontalRule,
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Strike,
        Text,
        TextStyle,
        Underline,
        Link.configure({
          openOnClick: false,
        }),
      ],
      editable: true,
      content: _this.value,
      // onCreate({ editor }) {
      //   editor.command.removeEmptyTextStyle()
      // },
      onUpdate({ editor }) {
        _this.$emit('input', editor.getHTML()); // HTML
        // _this.$emit('input', editor.getJSON()); // JSON
      },
      onTransaction({ editor }) {
        if (editor.isActive('heading')) {
          // アクティブなheadingにセレクトボックス値を変更
           const fz = _this.fontSizeArray.find(item => editor.isActive('heading', { level: item.v }));
            _this.fontSize = fz ? fz.v : null;
        } else {
          _this.fontSize = null;
        }
      },
    })
  },
  beforeDestroy() {
    this.editor.destroy();
  },
}
</script>

<style lang="scss" scoped>
.tiptap-editor {
  height: auto;
  min-height: 300px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  > div:first-of-type {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
  }

  ::v-deep .ProseMirror {
    height: 100%;
    min-height: 216px;
    padding: 0 0 10px;

    > p {
      line-height: 20px;
    }
  }
  .tiptap-editor-controls {
    display: flex;
    align-items: center;
    padding: 3px;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(170, 170, 204, 0.5);
    border-radius: 5px;
    margin: 10px 15px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      width: 36px;
      height: 36px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 5px;
      &:hover,
      &.is-active {
        font-weight: bold;
        background: #f5f5f5;
      }
    }

    hr {
      display: block;
      width: 1px;
      min-height: 30px;
      border: 1px solid #d0d0d0;
      border-width: 0 1px 0 0;
      margin: 0 5px;
    }
    .el-select {
      width: 70px;
      margin: 0 5px;
    }
  }
}
</style>
