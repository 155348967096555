<template>
  <div>
    <div v-if="drawerShow" class="backdrop" @click.stop="close" />
    <transition name="right">
      <div v-show="drawerShow" :id="id" class="side_drawer" :class="[mode]">
        <div ref="inner" class="inner" tabindex="0">
          <slot>
            <router-view />
          </slot>
        </div>
        <div class="circle" @click="close" />
      </div>
    </transition>

    <tw-to-top v-if="drawerShow" :container="$refs.inner" />
  </div>
</template>

<script>
import TwToTop from '@/components/atoms/TwToTop'

  export default {
    name: 'TwDrawer',
    props: {
      parent: String,
      closeConfirm: Boolean,
      mode: String,
      id: String,
    },
    components: {
      TwToTop,
    },
    computed: {
      drawerShow() {
        return this.$store.state.drawerShow;
      }
    },
    watch: {
      drawerShow() {
        if (this.drawerShow) {
          setTimeout(() => {
            this.$refs.inner.focus();
          }, 600);
        }
      }
    },
    methods: {
      close() {
        if (this.closeConfirm) {
          this.$emit('close');
        } else {
          this.$store.commit('SET_DRAWER', false);
          if (this.parent) {
            this.$router.push({name: this.parent}).catch(() => {});
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  .side_drawer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 80%;
    background: $color_white;
    padding: 0;
    @include card_shadow;
    z-index: 101;

    .inner {
      width: 100%;
      height: 100%;
      padding: 0;
      overflow-y: auto;
    }
  }

</style>

<style lang="scss" scoped>
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(0,0,0,.2);
  }
  

  .right-enter-active, .right-leave-active {
    transform: translate(0px, 0px);
    transition: transform 0.5s;
  }

  .right-enter, .right-leave-to {
    transform: translate(calc(100% + 30px), 0px);
  }

  .circle {
    position: absolute;
    top: 14px;
    left: -18px;
    width: 18px;
    height: 29px;
    background: $color_gray_800 url(../../assets/images/icons/chevron_right_white.svg) no-repeat 3px center;
    background-size: 16px auto;
    border-radius: 15px 0 0 15px;
    z-index: 11;
    cursor: pointer;

    &:hover {
      opacity: .5;
    }
  }
</style>
